import logo from './logo.svg';
import './App.css';
import env from "react-dotenv";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <img src="1.png" className="App-logo" alt="logo" />
        <p>
          Just simple page.
        </p>
        <p className='info'>Commit: {env.CI_COMMIT_SHORT_SHA}</p>
        <p className='info'>Date: {env.CI_PIPELINE_CREATED_AT}</p>
      </header>
    </div>
  );
}

export default App;